<template>
  <div class="kt-container kt-grid__item kt-grid__item--fluid">
    <div
      role="alert"
      v-show="errors.length > 0"
      v-bind:class="{ show: errors.length }"
      class="alert fade alert-danger"
    >
      <div class="alert-text" v-for="(error, i) in errors" :key="i">
        {{ error }}
      </div>
    </div>

    <div class="kt-portlet">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">Create User</h3>
        </div>
      </div>

      <div class="kt-portlet__body">
        <!-- <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group
                  id="input-group-1"
                  label="Email address:"
                  label-for="input-1"
                  description="We'll never share your email with anyone else."
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.email"
                    type="email"
                   
                    placeholder="Enter email"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Your Name:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.name"
                    
                    placeholder="Enter name"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-3"
                  label="Food:"
                  label-for="input-3"
                >
                  <b-form-select
                    id="input-3"
                    v-model="form.food"
                    :options="foods"
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-group id="input-group-4">
                  <b-form-checkbox-group
                    v-model="form.checked"
                    id="checkboxes-4"
                  >
                    <b-form-checkbox value="me">Check me out</b-form-checkbox>
                    <b-form-checkbox value="that">
                      Check that out
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>

                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button class="ml-2" @click="BacktoUserList" variant="danger">
                  Back to List
                </b-button>
              </b-form> -->

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <b-form-group
            id="example-input-group-11"
            label-for="example-input-11"
          >
            <b-form-input
              id="example-input-11"
              name="example-input-11"
              v-model="$v.form.username.$model"
              :state="validateState('username')"
              aria-describedby="input-11-live-feedback"
              placeholder="Username"
              :readonly="this.form.id > 0"
            ></b-form-input>

            <b-form-invalid-feedback id="input-11-live-feedback"
              >User name is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group id="example-input-group-0" label-for="example-input-0">
            <b-form-input
              id="example-input-0"
              name="example-input-0"
              v-model="$v.form.firstname.$model"
              :state="validateState('firstname')"
              aria-describedby="input-0-live-feedback"
              placeholder="First Name"
            ></b-form-input>

            <b-form-invalid-feedback id="input-0-live-feedback"
              >First name is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="example-input-group-12"
            label-for="example-input-12"
          >
            <b-form-input
              id="example-input-12"
              name="example-input-12"
              v-model="$v.form.lastname.$model"
              :state="validateState('lastname')"
              aria-describedby="input-12-live-feedback"
              placeholder="Last Name"
            ></b-form-input>

            <b-form-invalid-feedback id="input-12-live-feedback"
              >Last name is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group id="example-input-group-1" label-for="example-input-1">
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
              placeholder="Email Address"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback"
              >Email is required and a valid email
              address.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="example-input-group-15"
            label-for="example-input-15"
          >
            <b-form-input
              id="example-input-15"
              name="example-input-15"
              v-model="$v.form.phone.$model"
              :state="validateState('phone')"
              aria-describedby="input-15-live-feedback"
              placeholder="Mobile No"
            ></b-form-input>
            <b-form-invalid-feedback id="input-15-live-feedback"
              >Mobile No is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="example-input-group-2"
            label
            label-for="example-input-2"
          >
            <b-form-input
              type="password"
              id="example-input-2"
              name="example-input-2"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-2-live-feedback"
              placeholder="Password"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback" v-if="!$v.form.password.required"
              >Password is required.</b-form-invalid-feedback
            >
             <b-form-invalid-feedback id="input-2-live-feedback" v-if="!$v.form.password.passwordvalidation"
              >Password should be eight characters including one uppercase letter, one special character and alphanumeric characters.</b-form-invalid-feedback
            >
          </b-form-group>

          <v-col class="text-right">
            <b-button type="submit" id="kt_submit" variant="primary"
              >Submit</b-button
            >
            <b-button class="ml-2" @click="BacktoUserList" variant="danger">
              Back to List
            </b-button>
          </v-col>

          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { REGISTER } from "@/store/auth.module";
//import { LOGOUT } from "@/store/auth.module";
//import { SETERROR } from "@/store/auth.module";
import JwtService from "@/common/jwt.service";


import { validationMixin } from "vuelidate";
import { email, required,maxLength, minLength ,numeric,helpers} from "vuelidate/lib/validators";
 
const noWhitespace = (value) => value.indexOf(' ') === -1;
const namevalidation = helpers.regex("mob", /^[a-z\s]+$/i);
const passwordvalidation =helpers.regex("pass",/^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,8})/i)

export default {
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      // Remove this dummy login info
      form: {
        id: "0",
        email: "",
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        phone: "",
        gender: null,
        captchaToken: "",
        dateofbirth: "",
      },
      companyid:"0",
      number: [],
      country: [],
      finishMounted: false,
      info: null,
      Isphonevalid: null,
      key: "",
      gender: [
        { value: null, text: "Select a gender." },
        { value: "1", text: "Male" },
        { value: "2", text: "Female" },
      ],
    };
  },
   validations() {
    return {
      form: this.rules
    }
  },
  methods: {
    yourValidationMethod: function ({ number, isValid, country }) {
      if (this.$v.form.phone.$model != "") {
        this.Isphonevalid = isValid;
      }

      this.number = number;
      this.country = country;

      return isValid;
    },
    onInput({ number, isValid, country }) {
      this.number = number;
      this.country = country;
      //console.log(number + " " + isValid + " " + country.name);
      this.Isphonevalid = isValid;
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        id: "0",
        username: null,
        email: null,
        password: null,
        phone: null,
        gender: null,
        captchaToken: "",
        firstname: "",
        lastname: "",
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

    

      const firstname = this.$v.form.firstname.$model;
      const phone = this.$v.form.phone.$model;
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const lastname = this.$v.form.lastname.$model;
      const username = this.$v.form.username.$model;

    

 
      console.log(this.form.id);

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right"
      );

      this.$store
        .dispatch(REGISTER, {
          ID: this.form.id,
          email: email,
          password: password,
          UserName: username,
          mobileno: phone,
          FirstName: firstname,
          LastName: lastname,
          gender: "",
          dateofbirth: "",
          CompanyID:this.companyid
        })
        .then(() => this.$router.push({ name: "admindashboard" }))
        .catch((error) => {
          if (error)
            submitButton.classList.remove(
              "kt-spinner",
              "kt-spinner--light",
              "kt-spinner--right"
            );
        });
    },
    BacktoUserList() {
      this.resetForm();
      this.$router.push({ name: "admindashboard" });
    },
    vd_phone(val) {
      var phonePattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      return phonePattern.test(val) ? true : false;
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      return "url(' + require('@/assets/media/bg/bg-4.jpg')";
    },
    rules() {
      return this.form.id === "0"
        ? {
            firstname: {
              required,
              minLength: minLength(3),
              namevalidation
            },
            lastname: {
              required,
              minLength: minLength(3),
              namevalidation
            },
            email: {
              required,
              email,
            },
            username: {
              required,
              minLength: minLength(4),  
              noWhitespace             
            },
            password: {
              required,
            passwordvalidation,
              maxLength:maxLength(15)
            },
            phone: { required ,numeric},
          }
        : {
            firstname: {
              required,
              minLength: minLength(3),
                namevalidation
            },
            lastname: {
              required,
              minLength: minLength(3),
                namevalidation
            },
            email: {
              required,
              email,
            },
            username: {
              required,
              minLength: minLength(4),
              noWhitespace
            },
            password: {
               passwordvalidation,
              maxLength:maxLength(15)
            },
            phone: { required ,numeric},
          }
    },
  },
  created() {
    this.key = this.$route.params.key;
    const isAdmin = JwtService.getToken().split("~")[3];

    if(isAdmin == undefined || isAdmin == 0)
     {
       console.log("redirect to login admin");
        this.$router.push({ name: "adminlogin" });
     }
  },
  mounted() {
    if (this.$route.params.userobj != null) {
      console.log(this.$route.params.userobj.email);
      this.form.id = this.$route.params.userobj.id;
      this.$v.form.email.$model = this.$route.params.userobj.email;
      this.$v.form.firstname.$model = this.$route.params.userobj.firstName;
      this.$v.form.lastname.$model = this.$route.params.userobj.lastName;
      this.$v.form.phone.$model = this.$route.params.userobj.mobileNo;
      this.$v.form.username.$model = this.$route.params.userobj.userName;
      console.log(this.form.id);
    }  
    
     const facilityid = JwtService.getToken().split("~")[4];
     this.companyid=facilityid;
  },
};
</script>
